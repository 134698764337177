import React from "react";

const APropos=()=>{

    return(
        <section className="w-8/12 absolute right-0 ">

            a propos
        </section>
    )
}

export default APropos